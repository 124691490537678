<template>
  <div
    :id="`${db.saveField}${db.objectId ? db.objectId : ''}`"
    class="d-flex align-items-end flex-wrap"
  >
    <div class="field-wrapper" :class="args.inputClass">
      <label v-if="args.label" class="sub-label">{{ args.label }}</label>
      <b-input-group>
        <b-input-group-prepend v-if="args.prependText">
          <b-input-group-text>
            {{ args.prependText }}
          </b-input-group-text>
        </b-input-group-prepend>
        <b-input
          v-model="data"
          :placeholder="args.placeholder"
          :type="args.type"
          :state="serverErrors.length || errorsPost.length ? false : null"
          :disabled="skipped"
          debounce="500"
          :number="args.type === 'number'"
          class="form-control standard-input"
          :formatter="formatter"
          :lazy-formatter="!!formatter"
        />
        <b-input-group-append v-if="args.appendText">
          <b-input-group-text>
            {{ args.appendText }}
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>
    </div>
    <div v-if="args.skippable" class="add-or flex-shrink-0">
      <label class="fancy-checkbox-container"
        >Skip
        <input
          type="checkbox"
          :value="skipped"
          :checked="skipped ? 'checked' : null"
          @click="skipQuestion"
        />
        <span class="checkmark"></span>
      </label>
    </div>

    <div v-if="args.otherFunction" class="add-or flex-shrink-0">
      <label class="fancy-checkbox-container"
        >{{ args.otherFunction.text || "Other Function" }}
        <input type="checkbox" @click="$emit('otherFunction')" />
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>

<script>
import { skipHelpers } from "../questions/helpers/skipHelpers";
import { saveHelpers } from "@/views/questionnaires/saveHelpers";

export default {
  name: "InputBasic",
  mixins: [saveHelpers, skipHelpers],
  props: {
    value: {
      type: [String, Number],
      required: false,
    },
    db: {
      type: Object,
      required: true,
    },
    args: {
      type: Object,
      required: true,
    },
    skipped: {
      type: Boolean,
      required: false,
    },
    noSave: {
      type: Boolean,
      default: false,
    },
    errorsPost: {
      type: Array,
      default: () => {
        return [];
      },
    },
    formatter: {
      type: Function,
      required: false,
    },
  },
  data() {
    return {
      serverErrors: [],
      jsErrors: [],
    };
  },
  computed: {
    data: {
      set(value) {
        this.$emit("input", value);
        if (this.noSave) return;
        this.$nextTick(() => {
          this.save();
        });
      },
      get() {
        return this.value;
      },
    },
  },
};
</script>

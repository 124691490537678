import { clone } from "@/mixins/clone";
let get = require('lodash/get');

export const progressHelpers = {
    name: 'progressHelpers',
    mixins: [clone],
    // calculates progress of questionnaire and stores in the store
    watch: {
        numberOfSections: {
            immediate: true,
            handler(value) {
                this.$store.commit('numberOfSections', value)
            }
        },
        numberOfMinutes: {
            immediate: true,
            handler(value) {
                this.$store.commit('numberOfMinutes', value)
            }
        },
        '$store.getters.questionsForm': {
            // updates progress on form change
            immediate: true,
            deep: true,
            handler() {
                this.calculateStepsProgress()
            }
        },
        form: {
            // updates progress on form change
            immediate: true,
            deep: true,
            handler() {
                this.calculateStepsProgress()
            }
        },
        '$store.getters.fieldSets': {
            // updates progress on form change
            immediate: true,
            deep: true,
            handler() {
                this.calculateStepsProgress()
            }
        },

    },
    computed: {
        questionsForm() {
            return this.$store.getters.questionsForm
        },
        numberOfSections() {
            // length of questionnaire order array (field-sets)
            return this.order.length
        },
        order () {
            return this.$parent.$data.order
        },
        numberOfQuestions() {
            return Object.keys(this.form).length
        },
        numberOfMinutes() {
            return Number((this.numberOfQuestions * 2) / 60).toFixed(0)
        },
        fieldSets() {
            return this.$store.getters.fieldSets
        },
        progressFieldSets() {
            return this.$store.getters.progressFieldSets
        }
    },
    methods: {
        calculateStepsProgress() {
            // questions loading into store from questionLoaderHelpers
            let blankValues = [null, undefined, '']
            let questionsForm = this.$store.getters.questionsForm
            let questionKeys = Object.keys(questionsForm)
            let fieldSetKeys = Object.keys(this.fieldSets)

            let final = []
            // check every field set
            for (let x in fieldSetKeys) {
                let fieldSetQuestion = {}
                for (let y in questionKeys) {
                    // check every question in current fieldset
                    let validationObject = this.clone(questionsForm[questionKeys[y]])

                    if (validationObject.fieldSet === fieldSetKeys[x]) {
                        // add current question to current fieldset with desired attributes
                        // (value is an object, array of objects, or a simple value)
                        // (form value can be found from a path or just at root level of the form)
                        let value = null
                        // does question have a path to the form value (used in json db objects, i.e. will/lpa data)
                        // otherwise form value is at root level
                        let formValue = this.form[questionKeys[y]]

                        if ('formPath' in validationObject && validationObject.formPath) {
                            formValue = get(this.form, validationObject.formPath)
                        }

                        // validate form values
                        if ('objectValidationField' in validationObject &&
                            validationObject.objectValidationField &&
                            typeof formValue === 'object' && formValue !== null) {

                            // value is an object and question has objectValidationField field to validate the object to
                            value = formValue[validationObject.objectValidationField]

                        } else if (formValue && Array.isArray(formValue)) {
                            // value (answer) is an array of objects, turn into true or null for validation
                            value = formValue.length ? true : null
                        } else {
                            // value is simple string, boolean, number
                            value = formValue
                        }

                        fieldSetQuestion[questionKeys[y]] = {
                            value: value,
                            skippable: validationObject.skippable,
                            skipped: validationObject.skipped,
                            required: validationObject.required
                        }
                    }

                }

                let answeredQuestions = 0
                let fieldQuestionKeys = Object.keys(fieldSetQuestion)

                // check every question attribute in each fieldset to see if question is answered
                for (let i in fieldQuestionKeys) {

                    if (fieldSetQuestion[fieldQuestionKeys[i]].required === false) {
                        // if not required
                        answeredQuestions += 1
                    } else if (!fieldSetQuestion[fieldQuestionKeys[i]].skippable &&
                        !blankValues.includes(fieldSetQuestion[fieldQuestionKeys[i]].value)) {
                        // if not skipp-able and question answered
                        answeredQuestions += 1
                    } else if (fieldSetQuestion[fieldQuestionKeys[i]].skippable &&
                        (fieldSetQuestion[fieldQuestionKeys[i]].skipped ||
                            !blankValues.includes(fieldSetQuestion[fieldQuestionKeys[i]].value))) {
                        // if skipp-able and skipped or question answered
                        answeredQuestions += 1
                    }
                }


                // calculate fieldset progress from answered questions
                let result = Number((answeredQuestions / fieldQuestionKeys.length) * 100).toFixed(0)
                // add fieldset to results

                final.push({
                    label: this.fieldSets[fieldSetKeys[x]].label,
                    component: fieldSetKeys[x],
                    disableStepsNavigation: 'disableStepsNavigation' in this.fieldSets[fieldSetKeys[x]] ? this.fieldSets[fieldSetKeys[x]].disableStepsNavigation : false,
                    progress: result,
                    required: fieldQuestionKeys.length,
                    answered: answeredQuestions,
                    videoUrl:  this.fieldSets[fieldSetKeys[x]].videoUrl,
                    order: this.fieldSets[fieldSetKeys[x]].order
                })
            }

            // calculate overall progress result
            let totalRequired = final.reduce((acc, item) => acc + item.required, 0)
            let totalAnswered = final.reduce((acc, item) => acc + item.answered, 0)
            let result = Number((totalAnswered / totalRequired) * 100).toFixed(0)

            // store result in store
            this.$store.commit('progressPercentage', result)
            this.$store.commit('progressFieldSets', final)
            this.fieldSetProgress = final
        }

    }
}

import {httpQuestionnaire} from "@/services";
import { toast } from '@/mixins/toast'

export const saveHelpers = {
    mixins: [toast],
    name: 'saveHelpers',
    computed: {
        client() {
            return this.$store.getters.client
        }
    },
    watch: {
        serverErrors: {
            immediate: true,
            deep: true,
            handler(value) {
                this.$emit('serverErrors', value)
            }
        }
    },
    methods: {
        async save(dbSettingsLocation=null, dataLocation=null) {
            this.clearErrors()
            // db settings location (usually at this.db but can be overridden)
            let location = 'db'
            if (dbSettingsLocation) location = dbSettingsLocation

            // data location (usually this.data but can be overwritten)
            let valueLocation = 'data'
            if (dataLocation) valueLocation = dataLocation
            let saveField = this[location].jsonSaveField || this[location].saveField

            // insert server response data back into the form
            let returnDataInsert = this[location].returnDataInsert

            let path = null
            if (this[location].formPathSave) path = this[location].formPathSave
            else path = this[location].formPath


            let data = {
                [this[location].saveField]: this[valueLocation],
                save_field: saveField,
                value: this[valueLocation],
                path: path,
                return_data: returnDataInsert
            }

            // attach object id
            if (this[location].objectId) data.id = this[location].objectId

            return await httpQuestionnaire.patch(this[location].saveLocation, data).then(
                (response) => {
                    if (this.db?.returnDataInsert) this.data = response.data
                    if (this.db?.callbackFunction) this.db.callbackFunction()
                    return true
                }
            ).catch(
                error => {
                    if (error?.response?.data) {
                        this.handleErrors(error.response.data, location)
                    }
                    return false
                }
            )
        },
        clearErrors() {
            this.serverErrors = []
            if ('jsErrors' in this.$data) this.jsErrors = []
            this.$emit('jsErrors', [])
        },
        handleErrors(errors, location) {
            if (this[location].saveField in errors) {
                this.serverErrors = errors[this[location].saveField]
            }
        }
    }
}

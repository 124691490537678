
export const skipHelpers = {
    methods: {
        skipQuestion (args={}) {
            // this.data = null
            // args setup
            let emitSkip = 'emitSkip' in args ? args.emitSkip : true;
            let localSkip = 'localSkip' in args ? args.localSkip : false;
            let attributeToClear = 'attributeToClear' in args ? args.attributeToClear : 'data'
            let objectToClear = 'objectToClear' in args ? args.objectToClear : null
            let errorAttributeToClear = 'errorAttributeToClear' in args ? args.errorAttributeToClear : 'serverErrors'
            let errorsObjectToClear = 'errorsObjectToClear' in args ? args.errorsObjectToClear : null
            let callBackFunction = 'callBackFunction' in args ? args.callBackFunction : null
            let newSkipValue = !this.skipped
            // change skipped value in question
            if (emitSkip) this.$emit('skipped', newSkipValue)
            else if (localSkip) this.skipped = newSkipValue

            // if question is skipped then reset js and server errors and form value(s)
            this.$nextTick(() => {
                if (this.skipped) {
                    // clear server errors which may be in a dict of array
                    if (errorAttributeToClear && errorsObjectToClear) {
                        // if errors is object then clear all error attributes
                        let errorKeys = Object.keys(errorsObjectToClear)
                        for (let i in errorKeys) {

                            this.$set(this[errorAttributeToClear], errorKeys[i], [])
                            // this[errorAttributeToClear][errorKeys[i]] = []

                        }
                    } else if (errorAttributeToClear) {
                        this[errorAttributeToClear] = []
                    }
                    // clear js errors with are in an array
                    this.$emit('jsErrors', [])
                    // clear data which may be an object or value
                    // 1. clear object
                    if (attributeToClear && objectToClear) {
                        let dataKeys = Object.keys(objectToClear)
                        for (let i in dataKeys) {
                            this[attributeToClear][dataKeys[i]] = null
                        }
                    }
                    // 2. clear data
                    else if (attributeToClear) {
                        // this[attributeToClear] = null
                        this.$set(this, attributeToClear, null)
                    }
                    // run optional supplied callback function
                    this.$nextTick(() => {
                        if (callBackFunction) callBackFunction()
                    })
                }
            })

            this.$nextTick(() => {

                // update store: important to set original values for progress helpers
                let previousValue = this.$store.getters.questionsForm[this.db.saveField]
                let data = {data: {
                    skipped: newSkipValue,
                        skippable: this.args.skippable,
                        fieldSet: previousValue.fieldSet,
                        required: previousValue.required,
                        objectValidationField: previousValue.objectValidationField,
                        formPath: previousValue.formPath
                    },
                    attribute: this.db.saveField}
                this.$store.commit('questionsFormFieldUpdate', data)

            })

        }
    }
}

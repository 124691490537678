<template>
  <div class="fieldset">
    <template v-if="!show.login">
      <h3 class="fieldset-heading">Registration Details</h3>
      <b-alert v-if="codeDetails && codeDetails.error_message" show variant="danger">
        {{ codeDetails.error_message }}
      </b-alert>
    </template>

    <template v-else>
      <h3 class="fieldset-heading">Account Created</h3>
      <p>{{ show.loginMessage }}</p>
    </template>

    <div v-show="show.form">
      <RegisterEmail
          v-model="form.email"
          :post-errors="errors.email"
      />

      <RegisterPassword1
          v-model="form.password1"
          :post-errors="errors.password1"
      />

      <RegisterPassword2
          v-model="form.password2"
          :post-errors="errors.password2"
      />

      <RegisterSharedClientInformation
        v-model="form.partner_shared_information_terms"
        :post-errors="errors.partner_shared_information_terms"
      />

      <RegisterTerms
          v-model="form.terms_and_conditions"
          :post-errors="errors.terms_and_conditions"
      />

    </div>
  </div>
</template>

<script>
import {httpQuestionnaire} from "@/services";
import {toast} from '@/mixins/toast'
import {fieldsetHelpers} from "@/components/common/questionnaires/fieldsets/fieldsetHelpers";


import RegisterEmail from "@/components/common/questionnaires/question/questions/register/RegisterEmail";
import RegisterPassword1 from "@/components/common/questionnaires/question/questions/register/RegisterPassword1";
import RegisterPassword2 from "@/components/common/questionnaires/question/questions/register/RegisterPassword2";
import RegisterTerms from "@/components/common/questionnaires/question/questions/register/RegisterTerms";
import RegisterSharedClientInformation
  from "@/components/common/questionnaires/question/questions/register/RegisterSharedClientInformation.vue";

export default {
  name: 'Register',
  components: {
    RegisterSharedClientInformation,
    RegisterTerms,
    RegisterPassword2,
    RegisterPassword1,
    RegisterEmail,
  },
  mixins: [toast, fieldsetHelpers],
  mounted() {
    this.setEmail()
    this.$emit('finishButtonText', 'Create Account')
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    codeDetails() {
      return this.$store.getters.accessCodeDetails;
    }
  },
  data() {
    return {
      label: 'Create User', // used for steps progress
      fieldSetComplete: true,
      show: {
        form: true,
        login: false,
        loginMessage: null,
        security: false
      },
      errors: {
        email: [],
        password1: [],
        password2: [],
        terms_and_conditions: [],
        security_name_first: []
      }
    }
  },
  methods: {
    create() {
      console.log('create')
      // if already created then login
      if (this.show.login) return this.login()

      this.clearErrors()
      httpQuestionnaire.post('coupon_register', this.form).then(
          (response) => {
            this.$store.dispatch('_setTokens', response.data.tokens)
            this.$store.dispatch('userFetch', true)
            // remove coupon code as it interferes with questionnaire finish function
            this.$store.dispatch('removeAccessCode')
            this.toast('Client account created', 'Welcome')
            this.$router.push({name: 'ClientTransactionCouponDetail', params: {id: response.data.transaction}})

            // probably dont need the below code now reroutes to transaction
            // this.show.form = false
            // this.show.login = true
            // this.$emit('finishButtonText', 'Proceed to Login')
            // this.show.loginMessage = 'Success, your account is now created, you can now proceed to login.'
          }
      ).catch(
          error => {
            if ('code' in error.response.data) {
              if (error.response.data.code === 'already_registered') {
                this.toast('Already registered, please login', 'Error', 'danger')
                this.show.form = false
                this.show.login = true
                this.$emit('finishButtonText', 'Proceed to Login')
                this.show.loginMessage = 'You are already registered for this service, please login'
              }
            } else {
              this.handleErrors(error.response.data)
            }
          }
      )
    },
    clearErrors() {
      let keys = Object.keys(this.errors)
      for (let i in keys) {
        this.errors[keys[i]] = []
      }
    },
    handleErrors(errors) {
      let keys = Object.keys(errors)
      for (let i in keys) {
        this.errors[keys[i]] = errors[keys[i]]
      }
    },
    setEmail() {
      // set form email based on client profile
      if (this.$store.getters.client) this.form.email = this.$store.getters.client.profileEmail
    },
    login() {
      this.$store.dispatch('logoutClient')
    }
  }
}
</script>

<template>
  <div class="fieldset">
    <h6 class="fieldset-heading">Login</h6>

    <b-alert :show="!!codeDetails.error_message" variant="danger">
      {{ codeDetails.error_message }}
    </b-alert>

    <p>If you would like to view or amend this information please login to the Client Portal.</p>

    <p>The Client Portal allows you to update your information, ask questions, manage appointments and much more.</p>
    <button
        class="btn btn-forwards mt-30"
        @click="login">
      Client Portal Login
    </button>
  </div>
</template>

<script>

export default {
  name: 'LoginInformation',
  methods: {
    login() {
      // remove access tokens
      this.$store.commit('removeTokens');

      let data = {}
      // if there is a coupon apply to route params
      if (this.$route.params.coupon) {
        console.log('params has a coupon')
        data = {
          name: 'LoginClientWithCoupon',
          params: {coupon: this.$route.params.coupon}
        }
      } else {
        data = { name: 'LoginClient',}
      }
      console.log('route data', data)
      this.$router.push(data).catch((error) => {
        console.log(error);
      });
    }
  },
  computed: {
    codeDetails() {
      return this.$store.getters.accessCodeDetails;
    }
  }
}
</script>

<template>
  <b-modal
      v-model="show"
      id="mlw-user-agreement-modal"
      title="User Agreement"
      ok-title="I Agree"
      cancel-title="Decline"
      no-close-on-esc
      no-close-on-backdrop
      hide-header-close
      @ok="$emit('confirmed', true)"
      @cancel="$emit('confirmed', false)"
      size="lg"
  >
    <MlwUserAgreement/>
  </b-modal>
</template>

<script>


import MlwUserAgreement from "@/components/common/terms/MlwUserAgreement.vue";

export default {
  name: 'MlwUserAgreementModal',
  components: {MlwUserAgreement},
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
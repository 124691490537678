<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">All Done</h3>
    <p>Thank you for completing the information request, your records have been instantly updated with the information provided.</p>
  </div>
</template>

<script>
export default {
  name: 'Complete'
}

</script>
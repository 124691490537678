export const scrollTo = {
    methods: {
        scrollTo(elementId, duration=0.3) {
            var options = {
                container: 'body',
                easing: 'ease-in',
                offset: -160,
                force: true,
                cancelable: true,
                onStart: function () {
                    // scrolling started
                },
                onDone: function () {
                    // scrolling is done
                },
                onCancel: function () {
                    // scrolling has been interrupted
                },
                x: false,
                y: true,
            }
            this.$scrollTo(elementId, duration, options)
        },
    }
}
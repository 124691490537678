<template>
  <div>
    <div class="terms-intro">
      <p>
            <span>The mylastwill User Agreement comprises these Terms and Conditions, our
               Privacy Policyand all incorporated policies.</span><br><br>
      </p>
      <div class="terms-intro-hidden">
        <p>
          Before placing an Order on the Website, You will be asked to agree to these Terms and Conditions by
          clicking on the button marked 'I Accept' and then You will be able to proceed to complete Your Order at
          which point a Contract will
          come into existence between You and Us.
        </p>
        <br>
        <p>
          If You do not click on the button, You will not be able to complete Your Order and there will be no
          Contract. You can only purchase the Services and Goods from the Website if You are eligible to enter
          into a Contract and are at least
          18 years old.
        </p>
        <br>
        <p>

          As We can accept Your Order and make a legally enforceable agreement without further reference to You,
          You must read these terms and conditions to make sure that they contain all that You want and nothing
          that You are not happy with.
          If You are not sure about anything, please contact Us.
        </p>
      </div>
    </div>
    <ol>
      <li class="section terms-sec" id="1">
        <h6>Interpretation</h6>
        <ul>
          <li>
            <span>"Us" or "We"</span> mean mylastwill Ltd a company registered in England and Wales under company
            registration number 11667075 and our registered office is at Stallbrook Hall Granary Crossing Lane,
            Derrington, Stafford, United
            Kingdom,
            ST18 9AB
            with email address info@mylastwill.co.uk and our telephone number is 01785338292;
          </li>
          <br>
          <li><span> "Content"</span>
            means any copyright and related rights, trademarks and service marks, trade names and domain names,
            text, graphics, images, audio, video, software, data compilations, page layout, underlying code and
            software and any other
            intellectual property rights or form of information capable of being stored in a computer that appears
            on or forms part of this Website, including any such content uploaded by Users;
          </li>
          <br>
          <li>
            <span>"Contract"</span> means the legally-binding agreement between You and Us for the supply of the
            Services;
          </li>
          <br>
          <li>
            <span>"Durable Medium"</span> means paper or email, or any other medium that allows information to be
            addressed personally to the recipient, enables the recipient to store the information in a way
            accessible for future reference for a
            period that is
            long enough for the purposes of the information, and allows the unchanged reproduction of the
            information stored;
          </li>
          <br>
          <li>
            <span>"Checking Process"</span> means the process of Documents being submitted online for review by our
            team
          </li>
          <br>
          <li>
            <span>"Document"</span> means the will document(s) or any other document created through our website.
          </li>
          <br>
          <li>
            <span>"Fee"</span> means the sums paid by You to Us for the Documents and the Services;
          </li>
          <br>
          <li>
            <span>"Goods"</span> means any Document that We supply to You with the Services, of the number and
            description as set out in the Order;
          </li>
          <br>
          <li>
            <span>"Order"</span> means the Your order for the Services from Us as submitted following the step by
            step process set out on the Website;
          </li>
          <br>
          <li>
            <span>"Services"</span> means the services advertised on the Website, including any Goods, of the number
            and description set out in the Order;
          </li>
          <br>
          <li>
            <span>"Delivery"</span> means downloaded from our website or despatched for Delivery by Us;
          </li>
          <br>
          <li>
            <span>"Website"</span> means our website www.mylastwill.co.uk on which the Services are advertised.
          </li>
          <br>
          <li>
            <span>"You"</span> means the customer or user of the Goods and/or the Services and "Your" means
            belonging or pertaining to You.
          </li>
        </ul>


      </li>
      <li class="section terms-sec" id="2">
        <h6>Our Contract with You</h6>
        <p>These Terms and Conditions apply to any Contract made between You and Us.</p><br>
        <p>
          Before placing an Order on the Website, You will be asked to agree to these Terms and Conditions by clicking
          on the button marked 'I Accept' and then You will be able to proceed to complete Your Order at which point a
          Contract will come
          into existence between You and Us.
        </p><br>
        <p>
          You may only place an Order and enter into a Contract with us if you are 18 years old or older.
        </p><br>
        <p>
          The Order process is set out on the Website. Each step allows You to check and amend any errors before
          submitting the Order. It is Your responsibility to check that You have used the ordering process correctly
          and We may rely on the
          information which You provide to Us when using the ordering process as accurate and complete.
        </p><br>
        <p>
          A Contract will be formed for the Services ordered only when You make payment and have received electronic
          confirmation of the Order.
        </p><br>
        <p>
          No variation of the Contract can be made after it has been entered into unless the variation is agreed by
          You and by Us in writing using a Durable Medium and signed by an authorised representative of both parties.
        </p><br>
        <p>If We are unable to accept Your Order, We will inform You of this in writing and will not charge You for the
          product.
        </p>
      </li>
      <li class="section terms-sec" id="3">
        <h6>The Goods and Services</h6>
        <p>
          All Documents supplied are for private and domestic use by You only and You agree that any Documents ordered
          will not be used for commercial, business or resale purposes and We will have no liability to You for any
          such use.
        <p>
        <p>
          Descriptions of the Services provided as set out in the Website, brochures or other form of advertisement
          are for illustrative purposes only and actual Goods and Services supplied therefore may vary in appearance.
        </p>
        <p>
          A Document provided to you is personalised to You and Your particular circumstances by use of the
          information which You have provided. As a result, once Goods have been supplied to You, You will not be able
          to cancel the Contract without
          incurring liability for payment for those Goods. Goods will have been supplied to you when We have made a
          Document available to You so that You are able to download it (whether or not You have actually downloaded
          it).</p>

        <ul>We may have to suspend the supply of the Services to:<br><br>
          <li>deal with technical problems; or</li>
          <li>make material or technical changes; or</li>
          <li>make updates to reflect changes in relevant laws; or</li>
          <li>make updates to allow for changes in regulatory requirements; or</li>
          <li>maintain our computer systems.</li>
        </ul>
        <br>
        <p>
          Any such suspension will be for no longer than is reasonably necessary and We will have no liability to You
          by reason of any such suspension other than in the event that you have already made payment for Goods or
          Services and the
          suspension continues for more than 7 days after you made the payment. If that occurs, you will be entitled
          to a refund of the payment which you have made. On the making of any such refund by us, the Contract between
          us will come to an
          end.</p><br>
        <p>
          Our Goods and Services are suitable for wills under the laws of Great Britain. We do not accept Orders from
          or provide Services outside Great Britain.</p><br>
        <p>
          The Document and the Services are only suitable for wills relating to property and assets capable of being
          the subject of a will under the laws of Great Britain. If You own property outside of Great Britain or your
          property includes
          property or assets which may be required to be the subject of a will under the laws of another jurisdiction
          You should take steps to deal with that property in a manner that is effective in under the laws of that
          other jurisdiction and
          You may not rely on the Document as making valid provision for such property on Your death.
        </p>
      </li>
      <li class="section terms-sec" id="4">
        <h6>Our rights to make changes</h6>
        <ul>
          We may make changes to the Document:<br><br>
          <li>
            to reflect changes in relevant laws and regulatory requirements; and
          </li>
          <li>
            to implement minor technical adjustments and improvements, for example to address grammatical errors.
            These changes will not affect Your use of the Documents or the terms on which you do so or the Fee.
          </li>
        </ul>
        <br>
        <p>
          If We do make changes in the period between when You accept these terms and conditions and when We supply
          the Document or the Services to You We will notify You and You may then contact Us to end the contract
          before the changes take
          effect and receive a refund for any Goods or Services paid for but not received.</p>
        <br>
        <p>
          We may update or require You to update digital content, provided that the digital content shall always match
          in all material respects the description of it that We provided to You before You bought it.
        </p>
      </li>
      <li class="section terms-sec" id="5">
        <h6>Delivery</h6>
        <p>
          Digital draft copies of Documents are available for download by You shortly after payment is confirmed.</p>
        <br>
        <p>
          Digital final, checked copies of Documents are available for download by You as soon as the Checking Process
          is completed.</p><br>
        <p>
          Where You have elected for a printed copy of the Documents, Documents will be dispatched within 2 working
          days of the Checking Process having been completed.</p><br>
        <p>
          All correspondence and printed Documents will be sent to Your home address as identified in Your Order and
          which cannot be altered after you have placed Your Order using the Website.
        </p><br>
        <p>
          Where the supply of Documents or Services purchased is delayed by an event outside our control then We will
          contact You as soon as reasonably possible to let You know and We will take reasonable steps to minimise the
          effect of the delay
          .</p><br>
        <p>Provided We do this We will not be liable for delays caused by the event, but if the delay continues for 7
          days You may contact Us to end the Contract. If you do so you will be entitled to a refund of the part of
          the Fee paid for Goods
          and/or Services which have not be provided to you at that time that end the Contract.</p>
        <ul>
          If We do not deliver the Goods on time due to events within our control, You can (in addition to any other
          remedies) treat the Contract at an end if:<br><br>
          <li>
            We have refused to deliver the Goods, or if Delivery on time is essential taking into account all the
            relevant circumstances at the time the Contract was made, or You informed Us in writing (which includes
            by e mail) before the
            Contract
            was made that Delivery on time was essential; or
          </li>
          <li>
            after We have failed to deliver on time, You have specified a later period which is appropriate to the
            circumstances and We have not delivered within that period.
            .
          </li>
        </ul>
      </li>
      <li class="section terms-sec" id="6">
        <h6>Disclaimer</h6>
        <p>
          We are not a law firm and are not registered or regulated by the Solicitors Regulation Authority. We do not
          practice law and do not give legal advice. Any use of our Documents, Services or the Website does not create
          a lawyer-client
          relationship, and by using our Documents, Services or the Website no lawyer-client relationship will be
          created between Us or any employee of or other person associated with Us and You.
        </p><br>
        <p>
          The content and interpretation of any information or 'Guidance' provided does not constitute legal advice
          and should not be substituted for seeking professional legal advice. The Guidance constitutes information
          only and such information
          is not guaranteed to be correct and because the law changes We do not guarantee that all the information on
          the Website is completely up to date.
        </p><br>
        <p>
          As a result of changes to statute, case law and/or 'reinterpretation' of existing precedents (changes in the
          law) in the future Your Documents may become obsolete or unsuitable. You should review and consider whether
          you need to amend or
          update Your Document at least every 2 years and whenever Your personal circumstances change.
        </p>
      </li>
      <li class="section terms-sec" id="7">
        <h6>Customer Responsibilities</h6>
        <p>
          As with any legal matter, every situation is different. As such, our Services may not necessarily fit Your
          particular circumstances. You must satisfy Yourself that any Services rendered and/or Document provided are
          suitable for Your
          particular circumstances. We are not liable if any Document and/or Services You purchase from Us are not
          legally suitable or correct for Your particular circumstances.
        </p><br>
        <p>
          Failure to properly execute the Documents provided may result in an invalid or unenforceable Document.
          Written guidance is provided by Us with the Document and/or on the Website to assist You with the valid
          execution and attestation of
          Your Will in accordance with the Wills Act 1837. It is Your responsibility to follow such guidance and to
          ensure that any Document is validly executed.
        </p><br>
        <p>
          When registering to use the Website You set up a username and password. You remain responsible for all
          actions taken using Your username and password and undertake not to disclose Your username and password to
          anyone else. We will not be
          liable for anything consequential on any unauthorised access by a third party using Your username or email
          address.
        </p><br>
        <p>

          Except where carried out with our express advice provided to you in writing, any corrections and/or
          amendments to Your Document are Your responsibility and must be completed by logging in to the website and
          generating a new version of
          Your Will which must then be downloaded and executed.
        </p><br>
        <p>
          You must read the Document before execution, where You do not understand the contents of any Document it is
          Your responsibility to obtain clarification before you execute the Document.
        </p><br>
        <p>
          It is Your responsibility to ensure that You can receive and read (or have read to You) all communications
          sent to You by Us and to keep Your contact details up to date in our database (by logging in and updating
          them where necessary).
        </p><br>
        <p>
          You must co-operate with Us in all matters relating to the Services and provide Us and our authorised
          employees and representatives with all of the information required by Us to perform the Services.
        </p><br>
        <p>
          Failure to c-operate and/or to provide such information is a breach of the Contract by you which entitles Us
          to suspend performance of the Services until You provide such information and/or co-operation. If You fail
          to do so reasonably
          promptly following our request, We can terminate the Contract with immediate effect on written notice to
          You.</p>

      </li>
      <li class="section terms-sec" id="8">
        <h6>Limits on our Liability to You</h6>
        <p><strong>
          Please note the following provisions apply to limit the liability which We have to You.</strong></p><br>
        <p>
          The validity and suitability of any Document provided by us to you depends upon the honesty and accuracy of
          the answers which you provide to our questions. We will have no liability for any consequences arising from
          inaccurate or
          incomplete information provided by You.
        </p><br>
        <p>
          We have no responsibility and no liability for verifying Your identity or Your legal capacity to make a
          valid Will or for the consequences of your lack of such legal capacity.
        </p><br>
        <p>
          A Document signed under duress or undue influence may be invalid. Due to the nature of the Services We are
          not responsible for ensuring that You sign Your Documents free from duress or undue influence.
        </p><br>
        <p>
          It is not our responsibility to identify those who might have an actual or potential claim on Your Estate
          and We shall have no liability to You or Your estate or any actual or potential beneficiary who has or may
          have had an entitlement
          to Your estate for any loss they may incur as a result of Your use of the Services or a Document but who is
          not provided for or adequately provided for in a Document as a result of the failure of a Document to make
          any or adequate
          provision for any actual or potential beneficiary.
        </p><br>
        <p>
          During the Checking Process we ensure that the Documents produced reflect the instructions provided, but we
          do not review the instructions and information provided for accuracy, suitability or completeness and we
          shall have no liability
          for any consequences of any inaccurate or incomplete instructions provided to Us.
        </p><br>
        <p>
          If You make amendments to Your Documents after the Checking Process has been completed by Us You do so
          entirely at Your own risk and We shall not be liable for any consequences of such amendments or any other
          changes which You make to
          Your Documents which were not reviewed by us during the Checking Process.
        </p><br>
        <p>
          It is not our responsibility to make You aware of changes in statute or case law or the effect of these
          changes on Your Documents and we shall not be liable if We do not do so.
        </p><br>
        <p>
          If any Document We provide to You pursuant to the Contract between Us is defective following the Checking
          Process in that it does not accurately reflect the information provided to Us by You through the Website
          and/or is not a capable of
          being executed to create a valid will which reflects Your instructions, You will be entitled to refund of
          the sums that You have paid to Us for the Document under the contract.
        </p><br>
        <p>
          Any liability which We may have to You for any breach of the Contract between us or for anything else shall
          be limited to £2,500,000 inclusive of any indirect or consequential loss or damage.
        </p><br>
        <p>
          If You fail to act on any communication from Us to You which We have deemed to have been delivered to You in
          accordance with these terms and conditions then We shall not in any way be liable for any consequences.
        </p><br>
        <p>
          The limitations on our liability in these terms and conditions do not in any way exclude or limit our
          liability for death or personal injury caused by our negligence; or for the consequences of fraud or
          fraudulent misrepresentation by Us;
          or for defective Goods or Services under any statutory provision or any other liability which cannot be
          excluded or limited under the law of Great Britain.</p><br>
        <p>
          We shall have no liability to You for any failure to deliver any Goods or provide Services which is caused
          by any event or circumstance beyond our reasonable control, including without limitation, strikes, lock-outs
          and industrial
          disputes, breakdown of systems or network access, flood, fire, explosion or accident.
        </p>
      </li>
      <li class="section terms-sec" id="9">
        <h6>Fees and Payment</h6>
        <p>
          The Fee for the Services, the price of any Goods and any additional Delivery or other charges is that set
          out on the Website at the point of Order.
        </p><br>
        <p>
          The Fee and any other charges shown on the Website include VAT at the rate applicable at the time of the
          Order.
        </p><br>
        <p>
          The Fee is paid for the Delivery of the Document and/or provision of the Services. Should You decide not to
          sign the completed Document the fee for the Document will not be refunded.
        </p>
      </li>
      <li class="section terms-sec" id="10">
        <h6>Risk and Title</h6>
        <p>
          Risk of damage to, or loss of, any Document will pass to You when the Document is made available to you to
          download or where you have chosen to have the Document delivered to you, when the Document is delivered to
          You.
        </p><br>
        <p>
          You do not own the Documents until We have received payment in full.
        </p>
      </li>
      <li class="section terms-sec" id="11">
        <h6>The Website</h6>
        <p>
          Where the Website contains links to third party websites we are not liable for the content of such third
          party websites or the services or goods provided by any third party.
        </p><br>
        <p>
          You must be at least 18 years of age to use this Website. By using the Website and agreeing to these terms
          and conditions, You represent and warrant that You are at least 18 years of age.
        </p><br>
        <p>
          Any online facilities, tools, Services or information that We make available through the Website is provided
          "as is" and on an "as available" basis. We give no warranty that they will be free of defects and/or faults.
        </p><br>
        <p>
          We do not guarantee that the Website will be compatible with Your internet browser. In all cases where You
          encounter a problem accessing the Website and/or a Document or any of the Services from Your browser it is
          Your responsibility to
          find an alternative computer and/or browser to access the Website.
        </p><br>
        <p>
          Whilst We will use our reasonable endeavours to ensure that the Website is secure and free of errors,
          viruses and other malware, we give no warranty or guarantee in that regard and You use it and provide Your
          information through it at
          Your own risk.
        </p><br>
        <p>
          We reserve the right to alter, suspend or discontinue any part (or the whole of) the Website including, but
          not limited to, any Goods and/or Services available. These terms and conditions shall continue to apply to
          any modified version of
          the Website unless it is expressly stated otherwise.
        </p>
      </li>
      <li class="section terms-sec" id="12">
        <h6>Intellectual Property and Ownership</h6>
        <p>
          All Content included on the Website is our property or that of our affiliates or other relevant third
          parties. s. By continuing to use the Website You acknowledge that Content is protected by copyright,
          trademarks, database rights and
          other intellectual property rights. Nothing on the Website shall be construed as granting, by implication,
          estoppel, or otherwise, any license or right to use any trademark, logo or service mark displayed on the
          site without the owner's
          prior written permission.
        </p>
        <ul>
          You are permitted to print and download extracts from the Website for Your own use, provided that:<br><br>
          <li>
            You do not modify any documents or their related graphics in any way;
          </li>


          <li>
            You do not use any illustrations, photographs, video or audio sequences or any graphics separately from
            any accompanying text.; and
          </li>
          <li>
            You include an acknowledgement alongside the copied material noting mylastwill Ltd as the author and
            publisher.
          </li>
        </ul>
        <br>
        <p>
          You agree not to reproduce, duplicate, copy, sell, resell or exploit for any commercial purposes, any
          portion of the Website or any of its Content or any Document or otherwise.
        </p><br>
        <p>
          You agree that We may terminate or suspend Your right to use the Website if You breach, or if We have
          reasonable grounds to suspect that You have breached these provisions.
        </p>
      </li>
      <li class="section terms-sec" id="13">
        <h6> Accuracy of information</h6>
        <p>
          All information, materials and Content on the Website is provided for informational only and is not to be
          construed as legal advice or financial advice or tax advice and should not be relied upon by You as such.
        </p><br>
        <p>
          The information, including prices, and details, of Goods and Services, and any offers, included in this
          website, have been compiled from a variety of sources and are subject to change at any time without notice.
          In the event that such
          change takes place We shall not be liable to You in any way whatsoever.
        </p><br>
        <p>
          We do our best to ensure that all the information provided as part of the Website is correct at the time of
          inclusion but We do not represent that the information contained in the Website is accurate, comprehensive,
          verified or complete
          and We do not represent or warrant the currency, adequacy or suitability of the information to You and
          accept no liability for the accuracy or completeness of the information contained in the Website or for any
          reliance placed by any
          person on any information included in the Website.
        </p><br>
        <p>
          The information, content and material available on the Website may vary from time to time without notice to
          You. This is in order to ensure that the Website is as up to date as possible.
        </p><br>
        <p>
          The information on the Website is directed at residents of Great Britain only and applications from
          anyone who is not resident in Great Britain will not be accepted.
        </p><br>
        <p>
          The information and other materials contained on the Website may not satisfy the laws of any other country
          and those who choose to access this site from other locations are responsible for compliance with local laws
          if and to the extent
          local laws are applicable.
        </p><br>
        <p>
          The phone numbers provided only apply to phone calls made from within the UK.
        </p><br>
      </li>
      <li class="section terms-sec" id="14">
        <h6>Cancellation</h6>
        <p>
          Under regulation 29(1) of the Consumer Contracts (Information, Cancellation and Additional Charges)
          Regulations 2013 You have a right to cancel the contract provided that You notify Us within 14 days of the
          day following the date of
          Order.
        </p><br>
        <p>
          The right to cancel the contract under the Consumer Contracts (Information, Cancellation and Additional
          Charges) Regulations 2013 does not apply to a Document generated and downloaded digitally due to the
          instantaneous nature of the
          transaction and Delivery and does not apply to goods made to your specification. A Document supplied to you
          will be made to your specification as it will include or reflect the information and instructions which you
          have supplied to us.
        </p><br>
        <p>
          You have the right to cancel any subscriptions to Services (such as Document storage and updates) without
          giving any reason. To exercise this right to cancel, You must inform Us of Your decision to cancel this
          Contract by a clear
          statement setting out Your decision (eg a letter sent by post or email to the contact details stated in
          these terms and conditions or on the Website). You can also cancel subscriptions electronically on our
          website www.mylastwill.co.uk.
          If You use this option, We will communicate to You an acknowledgement of receipt of such a cancellation in a
          Durable Medium (eg by email) without delay.
        </p><br>
      </li>
      <li class="section terms-sec" id="15">
        <h6>Third Party Rights</h6>
        <p>
          A person who is not party to this contract has no right under the Contracts (Rights of Third Parties) Act
          1999 to enforce any term of this contract.
        </p><br>
      </li>
      <li class="section terms-sec" id="16">
        <h6>Governing law and jurisdiction</h6>
        <p>
          The Contract and any and all disputes relating to or arising from it are governed by the law of Great
          Britain.
        </p><br>
        <p>
          The courts of Great Britain shall have exclusive jurisdiction over any dispute relating to or arising from
          the Contract.
        </p><br>
      </li>
      <li class="section terms-sec" id="17">
        <h6>Entire Agreement and Variations to these Terms</h6>
        <p>
          These terms and conditions together with the current prices on the Website and the contact details set out
          the whole of agreement relating to the supply by Us of Goods and Services to You. Nothing said by any member
          of our staff or
          representative of Us will operate to vary these terms and conditions unless it is confirmed to You in A
          Durable Medium sent by a director of mylastwill ltd.
        </p><br>
        <p>
          We may vary these terms from time to time. Such variations will take effect 7 days after they are posted on
          the Website, but will not affect any contract concluded before the variations are posted on the Website. By
          continuing to use the
          Services you accept such variations.
        </p><br>
      </li>
      <li class="section terms-sec" id="18">
        <h6>Notices</h6>
        <p>
          Unless otherwise expressly stated in these terms and conditions, all notices sent in accordance with these
          terms and conditions from You to Us and from Us to You must be in writing and sent to our contact address at
          [address] and all
          notices from Us to You will be sent to the contact address which You provided to Us when placing Your Order
          on the Website.
        </p><br>
      </li>
    </ol>

  </div>
</template>

<script>
export default {
  name: 'MlwUserAgreement'
}
</script>

<style scoped lang="scss">
li {
  margin-bottom: 12px;
}
.terms-intro {
  font-style: italic;
}
</style>
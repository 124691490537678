<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Register for an account</h3>

    <template v-if="show.sent">
      <p>
        Request received, please check your inbox. If your email is registered with us then you should receive an email with a link to create your account. </p>
      <p>Not received the email? </p>
      <p><a href="#"
            @click="show.sent = false">Try Again</a></p>
    </template>

    <template v-else>
      <p>To register for an account, enter the email address that we have on file and we will send you a link to register.</p>
      <QuestionBase :errors="[...errors.email]"
                    :question="question.email"
                    :valid="!!form.email"
                    class="pb-0">
        <InputStandardBasic v-model="form.email"
                            :args="inputArgs.email"
                            :errors="errors.email" />
      </QuestionBase>

      <div class="button-nav">

        <button class="btn btn-forwards"
                @click="post">Request
        </button>
      </div>
    </template>


  </div>
</template>

<script>
import {httpQuestionnaire} from "../../../../services";
import QuestionBase from "../question/QuestionBase.vue";
import InputStandardBasic from "../../other/inputs/InputStandardBasic";

export default {
  name: 'EmailRegisterAccess',
  components: {
    InputStandardBasic,
    QuestionBase
  },
  data() {
    return {
      show: {
        sent: false
      },
      form: {
        email: null
      },
      question: {
        email: {
          title: 'What is your email address?',
          subTitle: 'Your Email Address is your username, please double check the email address is correct before confirming.',
          tip: null,
        }
      },
      inputArgs: {
        email: {
          placeholder: 'e.g. myemail@me.com',
          inputClass: 'field-75',
          label: 'Email'
        },
      },
      errors: {
        email: []
      }
    }
  },
  methods: {
    post() {
      httpQuestionnaire.post('client_access_regiter_email',
          this.form).
          then(() => {
            this.show.sent = true
          }).
          catch(error => {
            console.log(error)
          })
    }
  }
}
</script>
<template>
  <QuestionBase :question="question" :errors="[...postErrors, ...jsErrors, ...serverErrors]" :valid="!!data">
    <InputCheckBoxBasic
        @change="clicked"
        :value="data"
        :args="args"
        :errors="postErrors"
    />
    <MlwUserAgreementModal
        v-model="show.modal"
        @confirmed="data=$event" />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputCheckBoxBasic from "@/components/common/other/inputs/InputCheckBoxBasic";
import MlwUserAgreementModal from "@/components/common/terms/MlwUserAgreementModal.vue";

export default {
  name: 'RegisterTerms',
  mixins: [questionLoadHelpers],
  components: {
    MlwUserAgreementModal,
    InputCheckBoxBasic,
    QuestionBase
  },
  props: {
    value: {
      type: Boolean,
      required: false
    },
    saveLocation: {
      type: String,
      default: 'coupon_client'
    },
    postErrors: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  methods: {
    clicked (value) {
      // this.data = value
      console.log('terms clicked input', this.data, value)

      if (value) {
        this.data = value
        this.show.modal = true
      }

    }
  },
  data () {
    return {
      question: {
        title: 'Accept MyLastWill Terms & Conditions',
        subTitle: `Your account will be managed in line with our Privacy Policy and the terms and conditions.`,
        tip: null,
      },
      db: {
        saveLocation: this.saveLocation,
        saveField: 'terms_and_conditions'
      },
      args: {
        skippable: false,
        required: true,
        placeholder: null,
        inputClass: 'field-100',
        label: 'I Accept the Terms and Conditions',
        link: true
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
      show: {
        modal: false
      }
    }
  }
}
</script>

<template>

  <div id="top">
    <Header :title="title" sub-title="Please answer the questions"/>
    <Introduction/>

    <b-container
        v-show="questionsLoaded"
        id="start"
        class="main-content questionnaire"
    >
      <b-row style="flex-direction: row-reverse">
        <b-col v-if="show.steps" lg="4" offset-lg="1">
          <Steps :form="form"/>
        </b-col>
        <b-col class="fieldset-wrapper" lg="7">
          <b-row>
            <b-col>
              <div v-show="!show.complete">
                <transition-group name="slide-fade">
                  <slot></slot>
                </transition-group>
                <div v-if="showNavigationButtons" class="button-nav">
                  <button
                      v-show="showBackButton"
                      class="btn btn-backwards"
                      @click="back"
                  >
                    Back
                  </button>
                  <button
                      class="btn btn-forwards"
                      @click="next">
                    {{ nextButtonText }}
                  </button>
                </div>
              </div>

              <template v-if="show.complete">
                <Complete/>

                <SignUpForm v-if="$store.getters.client && !$store.getters.client.user_client"/>
                <LoginInformation v-else/>
              </template>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>


    <!-- Load Problems-->
    <b-container
        v-if="questionsLoadError"
        id="start"
        class="main-content questionnaire"
    >
      <b-row>

        <b-col class="fieldset-wrapper" lg="7">

          <template
              v-if="codeDetails && (codeDetails.expired || codeDetails.used || codeDetails.error_message)"
          >
            <LoginInformation
                v-if="codeDetails.registered"
            />
            <div v-else-if="codeDetails.action === 'REGISTER'">
              <Register v-model="formRegister" ref="register"/>

              <div class="button-nav">

                <button class="btn btn-forwards"
                        @click="register">Register
                </button>
              </div>
            </div>
            <div v-else>
              <EmailRegisterAccess
                  v-if="!codeDetails.registered"
              />

            </div>
          </template>
        </b-col>
      </b-row>
      <Footer/>
    </b-container>
  </div>
</template>

<script>
import {navigationHelpers} from "@/views/questionnaires/navigationHelpers";
import {progressHelpers} from "@/views/questionnaires/progressHelpers";

import Steps from "./Steps";
import Header from "./Header";
import Introduction from "./Introduction";
import Footer from "./Footer";
import Complete from "./Complete";
import SignUpForm from "@/components/common/questionnaires/fieldsets/register/SignUpForm";
import LoginInformation from "./LoginInformation";
import EmailRegisterAccess from "./EmailRegisterAccess";
import Register from "@/components/common/questionnaires/fieldsets/register/CouponClientRegister";

export default {
  name: "QuestionnaireBase",
  mixins: [navigationHelpers, progressHelpers],
  props: {
    title: {
      // question title override
      type: String,
      required: true,
    },
    form: {
      // question form from server
      type: Object,
      required: true,
    },
    finishFunction: {
      type: Function,
      required: false,
    },
    finishButtonText: {
      type: String,
      default: 'Finish'
    },
    continueButtonText: {
      type: String,
      default: 'Continue'
    },
    showNavigationButtons: {
      type: Boolean,
      default: true
    },
    customFinishRoutine: {
      type: Function,
      required: false
    }
  },
  components: {
    Register,
    EmailRegisterAccess,
    LoginInformation,
    SignUpForm,
    Complete,
    Footer,
    Introduction,
    Header,
    Steps,
  },
  data() {
    return {
      show: {
        steps: true,
        complete: false,
      },
      formRegister: {
        email: null,
        password1: null,
        password2: null,
        terms_and_conditions: null
      }
    };
  },
  computed: {
    questionsLoaded() {
      return this.$store.getters.questionsLoaded;
    },
    questionsLoadError() {
      return this.$store.getters.questionLoadError;
    },
    questionLoadError401() {
      return this.$store.getters.questionLoadError401;
    },
    codeDetails() {
      return this.$store.getters.accessCodeDetails;
    },
    isLocal() {
      return process.env.NODE_ENV !== "production";
    },
    parent() {
      return this.$parent
    }
  },
  methods: {
    register() {
      this.$refs.register.create()
    }
  }
};
</script>

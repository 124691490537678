<template>
  <li
      class="fieldset_progress"
      :class="{'completed': progressComplete, 'current': item.component === currentFieldSet, 'link': !item.disableStepsNavigation}"
      @click="gotToSection"
  >
    <h3 v-text="item.label"/>
    <div
        class="progress_label"
    >
      {{ progressText }}
    </div>
    <b-progress
        :value="item.progress"
        class="active"
        max="100"
    />
    <span class="d-none">80pc</span>
  </li>
</template>

<script>
import {scrollTo} from "@/mixins/scrollTo";

export default {
  name: 'StepsLine',
  mixins: [scrollTo],
  props: {
    item: {
      required: true,
      type: Object
    },
    currentFieldsetData: {
      type: String,
      required: false
    }
  },
  computed: {
    progressText() {
      if (!this.item.answered && !this.item.required) return '0/0'
      if (this.item.required === this.item.answered) return 'Complete'
      return `${this.item.answered}/${this.item.required}`
    },
    progressComplete() {
      return this.item.required === this.item.answered
    },
    currentFieldSet: {
      set(value) {
        this.$emit('currentFieldsetSet', value)
      },
      get() {
        return this.currentFieldsetData
      }
    }
  },
  methods: {
    gotToSection() {
      if (this.item.disableStepsNavigation) return
      // if (process.env.NODE_ENV === 'production') return
      this.currentFieldSet = this.item.component
      this.scrollTo('#top')
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;

  &:hover {
    h3 {
      text-decoration: underline;
    }
  }
}
</style>

<template>
  <QuestionBase :question="question" :errors="[...postErrors, ...jsErrors, ...serverErrors]" :valid="!!data">
    <InputBasic
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        :no-save="true"
        :errors-post="postErrors"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputBasic from "@/components/common/questionnaires/question/inputs/InputBasic";

export default {
  name: 'RegisterEmail',
  mixins: [questionLoadHelpers],
  components: {
    InputBasic,
    QuestionBase
  },
  props: {
    value: {
      type: String,
      required: false
    },
    saveLocation: {
      type: String,
      default: 'coupon_client'
    },
    postErrors: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'What is your email address?',
        subTitle: 'Your Email Address will be used as your username, please double check the email address is correct before confirming.',
        tip: null,
      },
      db: {
        saveLocation: this.saveLocation,
        saveField: 'email'
      },
      args: {
        type: 'text',
        skippable: false,
        required: true,
        placeholder: 'e.g. myemail@me.com',
        inputClass: 'field-75',
        label: 'Email'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>

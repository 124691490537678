<template>
  <div v-if="$store.getters.numberOfSections > 0" class="steps-wrapper">
    <div class="steps-card o-hidden">
      <vue-perfect-scrollbar>
        <div class="steps-card__image">
          <div v-if="videoSrc" class="player-container">

            <video width="100%" controls :src="videoSrc" type='video/mp4'>
              Your browser does not support the video tag.
            </video>

          </div>
        </div>
        <ul class="fieldset_progress-list">
          <div class="fieldset_progress_wrapper">
            <transition-group name="slide-fade">
              <template v-for="(item, index) in progressFieldSets">
                <StepsLine
                    :item="item"
                    :key="index"
                    :currentFieldsetData="currentFieldSet"
                    @currentFieldsetSet="currentFieldSet=$event"
                />
              </template>
            </transition-group>
          </div>
        </ul>
      </vue-perfect-scrollbar>

      <div class="steps-card__steps-stats clearfix">
        <div class="one-third">
          <div class="stat">
            {{ $store.getters.numberOfSections }}
          </div>

          <div class="stat-value">SECTIONS</div>
        </div>

        <div class="two-third no-border">
          <div class="stat">{{ isNaN($store.getters.progressPercentage) ? 0 : $store.getters.progressPercentage}}%</div>
          <div class="stat-value">COMPLETE</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepsLine from "./StepsLine.vue";

export default {
  name: 'Steps',
  components: {
    StepsLine,
  },
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  computed: {
    progressFieldSets() {
      let data = this.$store.getters.progressFieldSets
      return data.sort((a, b) => a.order - b.order)

    },
    currentFieldSet: {
      set(value) {
        this.$parent.$parent.$data.show = value
      },
      get () {
        return this.$parent.$parent.$data.show
      }
    },
    fieldSets() {
      return this.$store.getters.fieldSets
    },
    videoSrc() {
      let found = this.progressFieldSets.find(ob => ob.component === this.currentFieldSet)
      return found?.videoUrl ? found.videoUrl : null
    }
  }
}
</script>

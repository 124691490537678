<template>
  <QuestionBase :question="question" :errors="[...postErrors, ...jsErrors, ...serverErrors]" :valid="!!data">
    <InputCheckBoxBasic
       v-model="data"
        :args="args"
        :errors="postErrors"
    />
  </QuestionBase>
</template>

<script>
import { questionLoadHelpers } from "@/components/common/questionnaires/question/helpers/questionLoadHelpers";

import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputCheckBoxBasic from "@/components/common/other/inputs/InputCheckBoxBasic";

export default {
  name: 'RegisterSharedClientInformation',
  mixins: [questionLoadHelpers],
  components: {
    InputCheckBoxBasic,
    QuestionBase
  },
  props: {
    value: {
      type: Boolean,
      required: false
    },
    saveLocation: {
      type: String,
      default: 'coupon_client'
    },
    postErrors: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    }
  },
  data () {
    return {
      question: {
        title: 'Shared Client Information',
        subTitle: 'If you have a partner certain information will be shared across both of your accounts.',
        tip: null,
      },
      db: {
        saveLocation: this.saveLocation,
        saveField: 'partner_shared_information_terms'
      },
      args: {
        skippable: false,
        required: true,
        placeholder: null,
        inputClass: 'field-100',
        label: 'I Understand',
        link: true
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
      show: {
        modal: false
      }
    }
  }
}
</script>

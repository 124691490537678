import { httpQuestionnaire } from "@/services";
import { toast } from '@/mixins/toast'
import { scrollTo } from "@/mixins/scrollTo";

export const navigationHelpers = {
    mixins: [toast, scrollTo],
    computed: {
        orderLength() {
            // length of questionnaire order array
            return this.$parent.$data.order.length - 1
        },
        currentIndex() {
            // current loaded fieldset (component) within the questionnaire
            return this.$parent.$data.order.indexOf(this.$parent.$data.show)
        },
        nextButtonText() {
            // text displayed on the continue button
            return this.orderLength === this.currentIndex ? this.finishButtonText : this.continueButtonText
        },
        showBackButton() {
            // show the back button in not on the first component (fieldset)
            if (!this.$parent.$data.order.length) {
                // no components load into order
                return false
            } else if (this.$store.getters.accessCode) {
                return this.currentIndex !== 0
            } else if ('noInitialBackButton' in this.$parent.$data && this.$parent.$data.noInitialBackButton && this.currentIndex === 0) {
                return false
            } else  {
                return true
            }
        },
        user() {
            return this.$store.getters.user
        }
    },
    methods: {
        back() {
            if (this.currentIndex === 0) {
                return this.$router.go(-1)
            }
            // // go back (validation removed)
            // let errors = this.validate()
            // if (!errors.length) {
            if (this.orderLength >= this.currentIndex) {
                this.$parent.$data.show = this.$parent.$data.order[this.currentIndex - 1]
                this.scrollTo(`#top`)
            }
            // } else {
            //     this.scrollTo(`#${errors[0][1]}`)
            // }
            this.$parent.$data.finishButtonText = 'Finish'
            this.$parent.$data.continueButtonText = 'Continue'
        },
        async next() {
            /*
                Goto the next fieldset if there is no errors, or finish the questionnaire process
                (terminate client access_code and send email to user who created user access code)
            */

            // clear all question jsErrors
            this.clearErrors()

            // validate for question jsErrors
            let errors = this.validate()
            if (!errors.length) {

                try {
                    if ('navigateNextFunction' in this.$parent.$refs[this.$parent.$data.show].$data &&
                        this.$parent.$refs[this.$parent.$data.show].$data.navigateNextFunction) {
                        console.log('navigateNextFunction no scroll here')
                        let result = await this.$parent.$refs[this.$parent.$data.show].$data.navigateNextFunction()
                        if (!result) return
                    }
                } catch (error) {
                    console.log(error)
                    this.finishQuestionnaire()
                }


                if (this.orderLength > this.currentIndex) {
                    console.log('scroll 1')
                    this.$parent.$data.show = this.$parent.$data.order[this.currentIndex + 1]
                    this.$parent.$data.finishButtonText = 'Finish'
                    this.$parent.$data.continueButtonText = 'Continue'
                    this.scrollTo(`#top`)
                } else {
                    console.log('finish questionnaire')
                    this.finishQuestionnaire()
                }
            } else {
                // scroll to the first error
                this.scrollTo(`#${errors[0][1]}`)
            }
        },
        clearErrors (startLocation=this.$parent.$refs) {
            // clears all jsErrors on questions

            let keys = Object.keys(startLocation)

            for (let i in keys) {
                if (!startLocation[keys[i]]) continue

                for (let x in startLocation[keys[i]].$children) {
                    let question = startLocation[keys[i]].$children[x]
                    question.$data.jsErrors = []
                }
            }

        },
        validate(startLocation = this.$parent.$refs, errors = []) {
            console.log('validate')
            /*
               Valid for local errors client side, questions can be skipped.
               returns array of arrays with error message ond id of input with the error
            */

            // init variables
            let errorsAdded = errors
            let blankValues = [null, undefined, '']
            let keys = Object.keys(startLocation)

            for (let i in keys) {
                if (!startLocation[keys[i]]) continue

                for (let x in startLocation[keys[i]].$children) {
                    // init values
                    let question = startLocation[keys[i]].$children[x]

                    let args = {}
                    let db = null

                    if (question.$data && 'args' in question.$data) args = question.$data.args
                    else if ('args' in question) args = question.args

                    try {
                        db = question.$data.db
                    } catch (error) {
                        if ('db' in question.db) db = question.db
                    }

                    // console.log(question.$data, question, db)
                    let serverErrors = question.$data.serverErrors
                    let required = args.required
                    let customError = null
                    let customObjectError = null
                    // load custom error message from question
                    if ('customErrorMessage' in args && args.customErrorMessage) {
                        customError = args.customErrorMessage
                    }
                    if ('customErrorMessageNewOpenForm' in args && args.customErrorMessageNewOpenForm) {
                        customObjectError = args.customErrorMessageNewOpenForm
                    }

                    // init errors
                    let newErrorMessage = null
                    // guards
                    let dataAttributeExists = '$data' in question
                    if (!dataAttributeExists) continue
                    // create errors
                    // start to find errors
                    if (Array.isArray(serverErrors) && serverErrors.length) {
                        // if errors in an array
                        newErrorMessage = 'Please fix errors on this question'
                    } else if (serverErrors && serverErrors.constructor && serverErrors.constructor.name === 'Object') {
                        // if errors is an object
                        let foundErrorsCount = 0
                        let errorKeys = Object.keys(serverErrors)

                        for (let z in errorKeys) {
                            if (serverErrors[errorKeys[z]].length) {
                                foundErrorsCount += serverErrors[errorKeys[z]].length
                            }
                        }

                        if (foundErrorsCount) {
                            newErrorMessage = 'Please fix errors for this question'
                        }

                    }

                    // else if no data and not skipp-able and attribute is an object
                    if (db && 'validationField' in db && !question.data[db.validationField]) {
                        newErrorMessage = customError ? customError : 'Please answer this question'
                    }
                    else if (db && 'validationField' in db && Array.isArray(question.data[db.validationField]) && !question.data[db.validationField].length) {
                        newErrorMessage = customError ? customError : 'Please answer this question by adding details'
                    }
                    // check for open forms that have not been saved, this is positioned at end of forms to prioritise open form errors
                    else if ('form' in question.$data && question.$data.form && !question.$data.db.ignoreForm) {
                        // do not attempt to save as method could be saveObject or savePerson, plus other complications
                        // this.$parent.$refs[keys[i]].$children[x].saveObject(form, saveField)
                        newErrorMessage = customObjectError || "Save before proceeding by clicking 'Confirm'"

                    }

                    // check as above but for multiple forms
                    else if ('forms' in question.$data && question.$data.forms) {
                        for (let i in question.$data.forms) {
                            if (question.$data.forms[i]) {
                                newErrorMessage = customObjectError || "Save before proceeding by clicking 'Confirm'"
                            }
                        }
                    } else if (args.skippable &&
                        !question.$data.skipped &&
                        blankValues.includes(question.data)) {
                        // if no data and skipp-able and not skipped and data is single attribute
                        newErrorMessage = customError ? customError : 'Please answer this question or skip'
                    } else if (required === false) {
                        // if not required then ignore
                        continue
                    }

                    // else if no data and not skipp-able and attribute is an object
                    else if (db && 'objectValidationField' in db && !question.$data[db.objectValidationField]) {
                        newErrorMessage = customError ? customError : 'Please answer this question'
                    }

                    // else if data is an array and nothing in it
                    else if (Array.isArray(question.data) && !question.data.length) {
                        newErrorMessage = customError ? customError : 'Please answer this question by adding'
                    }

                    // else if no data and not skipp-able
                    else if ('data' in question && blankValues.includes(question.data) && !args.skippable) {
                        newErrorMessage = customError ? customError : 'Please answer this question'
                    }

                    // add errors to questions
                    if (!newErrorMessage) continue
                    if(db){
                        // add error message and div ref/id for scrollTo
                        errorsAdded.push([newErrorMessage, db.saveField])
                        question.$data.jsErrors = [newErrorMessage]
                    }

                }
            }
            return errorsAdded
        },
        async finishQuestionnaire() {
            if (this.$parent.$data.order) {
                // questionnaire defined function to run on finish button
                if (this.finishFunction) {
                    await this.finishFunction()
                }
                if (this.customFinishRoutine) {
                    return this.customFinishRoutine()
                }
            }

            // reset/finish user/client access code
            if (this.$store.getters.accessCode) {
                const finishRoutine = () => {
                    this.scrollTo(`#top`)
                    this.toast('Questionnaire complete, thank you')
                    this.show.complete = true
                }
                // send user completed to server
                if (this.$parent.$data.expiryAccessCodeOnFinish) {
                    httpQuestionnaire.patch('client_access_request_complete', {
                        access_code: this.$store.getters.accessCode,
                        complete: true
                    }).then(
                        () => {
                            finishRoutine()
                        }
                    ).catch(
                        error => {
                            console.log(error)
                        }
                    )
                } else {
                    finishRoutine()
                }
            }
            // redirect to introducer or client to transaction page
            else if (this.user && this.user.is_introducer) {
                // is a introducer
                if (this.$route.params.transactionId) {
                    // return introducer to transaction
                    this.$router.push({ name: 'IntroducerTransactionDetail', params: { id: this.$route.params.transactionId } })
                } else {
                    // return client to dashboard
                    this.$router.push({ name: 'IntroducerDashboard' })
                }
            } else if (this.user && this.user.is_client) {
                // is a client
                if (this.$route.params.transactionId) {
                    // return client to transaction
                    this.$router.go(-1)
                    // disbaled: could be a standard transaction or a coupon transaction so just goback one..
                    // this.$router.push({ name: 'ClientTransactionDetail', params: { id: this.$route.params.transactionId } })
                } else {
                    // return client to dashboard
                    this.$router.push({ name: 'ClientDashboard' })
                }
            }
        }

    }
}

export const questionLoadHelpers = {
    watch: {
        '$store.getters.questionsForm': {
            immediate: true,
            deep: true,
            handler(value) {
                if (this.db.saveField && !(this.db.saveField in this.$parent.form)) {
                    // set question in server form not initially from server
                    // (used for additional questions that are not saved)
                    this.$set(this.$parent.form, this.db.saveField, null)
                }
                this.$nextTick(() => {

                    if (this.db.saveField && !(this.db.saveField in value)) {
                        this.loadQuestion()
                    } else if (this.db.saveField in value) {
                        this.setSkipped()
                    }
                })

            }
        }
    },
    computed: {
        questionsFormStore() {
            return this.$store.getters.questionsForm
        },
        currentShownFieldSet() {
            if ('show' in this.$parent.$parent.$parent.$parent.$parent.$data) {
                let data = this.$parent.$parent.$parent.$parent.$parent.$data
                if ('load' in data && data.load) {
                    return data.load
                }
                return data.show
            } else if ('show' in this.$parent.$parent.$parent.$parent.$data) {
                let data = this.$parent.$parent.$parent.$parent.$data
                if ('load' in data && data.load) {
                    return data.load
                }
                return data.show
            }
        },
        currentShowFieldSetLabel() {
            return this.$parent.$parent.$parent.$data.label
        }
    },
    beforeDestroy() {
        // removes question from store if not loaded into dom (used for progress calculation)
        if (this.db.saveField in this.$store.getters.questionsForm && this.initialFieldSet === this.currentShownFieldSet) {

            // if (this.db.saveField in this.$store.getters.questionsForm && this.initialFieldSet === this.$parent.$options.name) {
            // remove question from store for progress
            this.$store.dispatch('questionFormRemoveAttribute', this.db.saveField)
        }
    },
    data() {
        return {
            initialFieldSet: this.getComponent().$data.show
        }
    },
    methods: {
        loadQuestion() {
            // load question into store form (used for progress calculation and skipp-able persistent state)
            let attribute = this.db.saveField

            let data = {
                skipped: false,
                skippable: this.args.skippable,
                fieldSet: this.currentShownFieldSet,
                required: this.args.required,
                objectValidationField: this.db.objectValidationField, // used to specify validation for objects
                formPath: this.db.formPath // path to the form data (used json db object i.e. a will)
            }

            this.$store.commit('questionsFormFieldUpdate', {data: data, attribute: attribute})
        },
        setSkipped() {
            // loads skipped state in question from store (used for progress calculation)
            this.skipped = this.$store.getters.questionsForm[this.db.saveField].skipped
        },
        getComponent() {
            // dynamically get the parent to QuestionnaireBase, this should be where the relevant show data is stored
            let componentName = 'QuestionnaireBase'
            let component = null
            let parent = this.$parent
            while (parent && !component) {
                if (parent.$options.name === componentName) {
                    component = parent
                }
                parent = parent.$parent
            }
            return component.$parent
        },
    }
}

<template>
  <div class="footer_wrap">
    <div class="flex-grow-1"></div>
    <div class="app-footer shadow">
      <div class="row">
        <div class="col-md-9">
          <p><strong>The Dunham McCarthy Group</strong></p>
          <p>
            All information is stored on our secure server and processed in line with our terms of business and privacy policy.
          </p>
        </div>
      </div>
      <div
          class="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center"
      >
        <div class="d-flex align-items-center">
          <img alt="" class="logo" src="@/assets/images/dm-group-logo.png"/>
          <div>
            <div>
              <p class="m-0">&copy; {{ new Date().getFullYear() }} TDMG</p>
              <p class="m-0">All rights reserved</p>
            </div>
          </div>
          <span class="flex-grow-1"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Footer'
};
</script>

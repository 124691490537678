export const fieldsetHelpers = {
    mounted() {
        this.loadFieldSet()
    },
    methods: {
        getBase(parentIn = null) {
            // dynamically get the parent to QuestionnaireBase, this should be where the relevant show data is stored
            // let component = null
            let parent = parentIn
            if (!parent) {
                parent = this.$parent
            }

            if ('$data' in parent && 'show' in parent.$data && 'order' in parent.$data) {
                return parent
            }
            return this.getBase(parent.$parent)
        },
        async loadFieldSet() {

            let result = await this.checkCondition()

            let base = this.getBase()

            if (result) {
                let data = {
                    data: {
                        label: this.label,
                        videoUrl: this.videoUrl,
                        disableStepsNavigation: this.disableStepsNavigation,
                        order: base.$data.order.indexOf(this.$options.name)
                    },
                    attribute: this.$options.name,
                }
                this.$store.commit('fieldSetsUpdate', data)
            }
        },
        async checkCondition() {
            // should the fieldset be loaded: used if a condition function is supplied from the fieldset
            if (this.loadCondition && !this.loadCondition()) {
                let base = this.getBase()
                // do not load fieldset
                // remove from show order list in questionnaire
                let currentFieldSetName = this.$options.name
                let index = base.$data.order.findIndex(option => option === currentFieldSetName)

                if (index >= 0) {
                    base.$data.order.splice(index, 1)
                    return false
                } else {
                    return false
                }

            } else {
                return true
            }
        }
    }
}
